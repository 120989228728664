.cameraWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    z-index: 0;
    background: transparent;
}

/* Add some padding inside the card container */
.cameraContent {
    background: transparent;
}

.cardTitle {
    color: white;
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    position: relative;
    font-size: calc(12px + 1.3vw);
    margin-top: -90px;
    z-index: 3;
    height: 40px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3),
    2px 2px 2px rgba(0, 0, 0, 0.3),
    3px 3px 3px rgba(0, 0, 0, 0.3);
}

.reactPlayer video {
    border-radius: 40px;
    box-shadow: 3px 3px 15px 4px rgba(0, 0, 0, 0.25),
    -3px -3px 15px 4px rgba(0, 0, 0, 0.22);
    transition: 0.3s ease-in-out;
    transform : translate(0px,0px);
}

.reactPlayer video:hover {
    box-shadow: 3px 3px 15px 7px rgba(0, 0, 0, 0.25),
    -3px -3px 15px 7px rgba(0, 0, 0, 0.22);
    transform: scale(0.9, 0.9);
}

.fullPlayer {
    position:fixed;
    top : 0px;
    left : 0px;
    z-index: 10;
    transform : scale(1);
}
.fullPlayer video{
    transform : translate(0px,0px);
}

.formControlLabel span {
    font-size: 15px;
}