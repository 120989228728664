.moviePage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.cameraContent video {
    border-radius: 15px;
    cursor: pointer;
}

.pagination {
    display: flex;
    align-items: center;
    text-align: center;
}

.pagination li {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    margin: 0 2px;
}

.pagination button,
.pagination .direction {
    font-size: 20px;
    position: relative;
    border-radius: 4px;
    display: block;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: -1px;
    color: #2f3640;
    text-decoration: none;
    transition: all .3s ease-in;
    cursor: pointer;
    background-color: transparent;
    outline:none;
    border: none;
}

.pagination a:hover,
.pagination .direction:hover {
    background-color: #ffb74d;
    border-color: #ffb74d;
    color: #fff;
    text-decoration: none;
}

.active button {
    background-color: #ffb74d;
    border-color: #ffb74d;
    color: #fff;
    text-decoration: none;
}

.pagination .disabled {
    width: 40px;
    height: 40px;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}

.movieTitle p {
    font-size: calc(15px + 0.9vw);
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 5px;
}

.movieDate p {
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: calc(10px + 0.5vw);
}