.personContent img {
    border-radius: 15px 15px 0 0;
    cursor: pointer;
    width : 100%;
    height : 100%;
}

.personTitle p {
    font-size: calc(15px + 0.5vw);
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.personDate p {
    margin-left: 15px;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: calc(10px + 0.5vw);
}